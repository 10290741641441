import { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {
    const form = useRef<HTMLFormElement>(null);
    const [buttonText, setButtonText] = useState('Küldés');
    const [buttonStatus, setButtonStatus] = useState('Küldés');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const serviceId = process.env.REACT_APP_SERVICE_ID;
        const templateId = process.env.REACT_APP_TEMPLATE_ID;
        const publicKey = process.env.REACT_APP_PUBLIC_KEY;
      
        if (!serviceId || !templateId || !publicKey || !form.current) {
          console.error('Environment variables are not defined properly.');
          return;
        }

        setButtonText("Küldés...");
        emailjs
          .sendForm(
            serviceId,
            templateId,
            form.current, {
                publicKey: publicKey,
            }
          )
          .then(
            () => {
              console.log('SUCCESS!');
              setButtonText("Elküldve");
              if (form.current) {
                form.current.reset();
              }
            },
            (error) => {
              console.log('FAILED...', error.text);
              setButtonText("Hiba");
            },
          );
    };

    return (
    <section className="contact" id="kapcsolat">
        <Container>
        <h1>Kapcsolat</h1>
        <Row className="align-items-center contact-box">
            <Col size={12} md={12}>
                <h3>
                    Örömmel fogadom megkereséseiket!
                </h3>
                <h4>
                    Képeimet eladásra is kínálom, de egyéb ügyben is bátran vegyék fel velem
                    a kapcsolatot az alábbi űrlap kitöltésével vagy  a megadott elérhetőségek egyikén.
                </h4>
                
                <form ref={form} onSubmit={handleSubmit}>
                    <Row>
                    <Col size={12} sm={6} className="px-1">
                        <input type="text" name="lastName" placeholder="Vezetéknév"/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                        <input type="text" name="firstName" placeholder="Keresztnév"/>
                    </Col>   
                    <Col size={12} sm={6} className="px-1">
                        <input type="email" name="email" placeholder="Email cím"/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                        <input type="tel" name="phone" placeholder="Telefonszám"/>
                    </Col>
                    <Col size={12} className="px-1">
                        <textarea rows={6} name="message" placeholder="Üzenet"></textarea>
                        <button className={buttonStatus} type="submit"><span>{buttonText}</span></button>
                    </Col>
                    </Row>
                </form>
                <Row className="contact-elements">
                    <Col  xs={12} md={12} lg={6} className="px-1 d-flex justify-content-center">
                        <h3>
                            <svg fill="#f6f5f4" width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                            <a href="mailto:rajzadam@gmail.com"> rajzadam@gmail.com</a>
                        </h3>
                    </Col>
                    <Col xs={12} sm={12} lg={6} className="px-1 d-flex justify-content-center">
                        <h3>
                            <svg fill="#f6f5f4" width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>	
                            <a href="tel:+36208021410"> +36 20 802 1410</a>
                        </h3>
                    </Col>
                </Row>
            </Col>
        </Row>
        </Container>
    </section>
    )
}
