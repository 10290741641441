import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Home } from './pages/Home';
import { Ima } from './pages/Ima';
import { Letvagy } from './pages/Letvagy';
import { Tollrajz } from './pages/Tollrajz';
import { Vegyes } from './pages/Vegyes';
import ScrollToTop from './helpers/ScrollToTop';
import {Helmet} from "react-helmet";
import favicon1 from "../src/favicon-16x16.png";
import favicon2 from "../src/favicon-32x32.png";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Lévai Ádám</title>
        <link rel="icon" type="image/png" sizes="32x32" href={favicon2} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon1} />
        <meta name="description" content="Lévai Ádám képzőművész, képgrafikus." />
        <meta name="keywords" content="lévai ádám, lévai, grafikus, képzőművész, képgrafikus, képek, portfólió, tata, artist, fine art, visual artist, pictures, portfolio" />
        <link rel="canonical" href="https://www.levaiadam.com" />
        <meta property="og:title" content="Lévai Ádám" />
        <meta property="og:description" content="Lévai Ádám képzőművész, képgrafikus." />
        <meta property="og:url" content="https://www.levaiadam.com" />
      </Helmet>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ima" element={<Ima />} />
          <Route path="/letvagy" element={<Letvagy />} />
          <Route path="tollrajzok" element={<Tollrajz />} />
          <Route path="/vegyes" element={<Vegyes />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
