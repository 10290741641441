import { Col, Row } from "react-bootstrap";

export const AboutMe = () => {
    return(
        <section className='rolam-section' id="rolam">
            <div className='container'>
                <h1>Rólam</h1>
                <div className='row'>
                    <div className="col-12">
                        <div className="rolam-bx">
                            <h2>Önéletrajz</h2>
                            <div className='rolam-text'>
                                <p>
                                    1967-ben születtem Tatán. 1994-ben diplomáztam a Magyar Képzőművészeti Egyetem képgrafika szakán.
                                    Több mint ötven önálló kiállításom volt itthon és külföldön. Számos díjban és elismerésben részesültek 
                                    munkáim, mint Barcsay Jenő díj I., II., Magyar grafikáért díj, Diploma nívó dij, Komárom-Esztergom 
                                    megyei Príma díj, ösztöndíjak.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="rolam-bx">
                            <h2>„Próféciák rajzban”</h2>
                            <h3>Lévai Ádám munkáihoz</h3>
                            <h3>Sinkó István - képzőművész, író</h3>
                            <Row className="rolam-row">
                                <Col xs={12} md={6}>
                                    <p>
                                        Akárha az emberiség mitikus múltjába érkeznénk Lévai Ádám rajzain keresztül. Furcsa sosemvolt, mégis mágikus alakok-kapaszkodnak össze, 
                                        belőlük újabb és újabb lények bukkannak elő. Jövendölés a múltból a jelennek? Lévai meghatározhatatlan korokat idéző és meghatározhatatlan 
                                        szürreális alakjai, jelenetei jövendölésként is megállják a helyüket. A drámai vagy meghökkentő jelenetek, az egymásba kapaszkodó alakok, a 
                                        rájuk, belőlük élősködőként megjelenített kisebb lények mind-mind egy-egy rémisztő jóslat szereplői is lehetnek. Nyúzott emberbőrök, csonkolt 
                                        növények válnak előttünk új alakban hősökként vagy áldozatokká.
                                    </p>
                                </Col>
                                <Col xs={12} md={5}>
                                    <p>
                                        Metamorfózis és apokalipszis egy képben elbeszélve. Lévai kvázi egyvonalas rajzai egy biztos kezű csapongó fantáziájú grafikus illusztrátor 
                                        munkái. Műveit akár a Jelenések könyve vagy egy távol-keleti misztériumtörténet, esetleg a Tibeti halottaskönyv szövegeinek ábrázolásaként is 
                                        elfogadnánk. Lévai szárnyaló fantáziája lendületes rajzokban ölt testet. A részletek sokasága ugyanakkor nem vész el az egész, a drámai mozgalmasságú 
                                        fő téma mellett. A remek arányokkal kiporciózott döbbenetes képi világ minket (is) figyelmeztet: „Változtasd meg Önmagad!”
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    );
}