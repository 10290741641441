import { AboutMe } from "../components/AboutMe";
import { Banner } from "../components/Banner";
import { Contact } from "../components/Contact";
import { Footer } from "../components/Footer";
import { NavBar } from "../components/Navbar";
import { Works } from "../components/Works";

export const Home = () => {
    return (
      <div className="Home">
        <NavBar />
        <Banner />
        <AboutMe />
        <Works />
        <Contact />
        <Footer />
      </div>
    );
}