import { useState, useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const handleSelect = () => {
      setExpanded(false);
    };

    useEffect(() => {
        const onScroll = () => window.scrollY > 50 ? setScrolled(true) : setScrolled(false);

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const onUpdateActiveLink = (value: any) => {
        setActiveLink(value);
    }

    return (
        <Navbar expand="md" expanded={expanded} className={ scrolled ? "scrolled" : "" } variant="dark">
          <Container>
            <Navbar.Brand href="#">LÉVAI ÁDÁM</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto" onSelect={handleSelect}>
                <Nav.Link href="#rolam" className={ activeLink === 'rolam' ? 'active navbar-link' : 'navbar-link' } onClick={ () => onUpdateActiveLink('rolam')}>Rólam</Nav.Link>
                <Nav.Link href="#munkaim" className={ activeLink === 'munkaim' ? 'active navbar-link' : 'navbar-link' } onClick={ () => onUpdateActiveLink('munkaim')}>Munkáim</Nav.Link>
                <Nav.Link href="#kapcsolat" className={ activeLink === 'kapcsolat' ? 'active navbar-link' : 'navbar-link' } onClick={ () => onUpdateActiveLink('kapcsolat')}>Kapcsolat</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );
}