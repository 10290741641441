import React, { useState } from 'react';
import { CloseButton, Container,Modal } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

declare const require: {
    context: (
      directory: string,
      useSubdirectories?: boolean,
      regExp?: RegExp
    ) => {
      keys: () => string[];
      <T>(id: string): T;
    };
};

const breakpointColumnsObj = {
    default: 3,
    1200: 2,
    700: 1
};

export const Gallery= ({ images }: any) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalImage, setModalImage] = useState<string>("");

    const handleImageClick = (imageUrl: string) => {
        setModalImage(imageUrl);
        setShowModal(true);
      };
    
      const handleCloseModal = () => {
        setShowModal(false);
      };

    return (
        <Container>
            <div className="image-grid">
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    {images.map((image: any, index: number) => (
                        <div className='masonry-img' onClick={() => handleImageClick(image)}>
                            <img 
                                src={image} 
                                alt={`Image ${index + 1}`} 
                                className="img-fluid"    
                            />
                        </div>
                    ))}
                </Masonry>
            </div>
        <Modal size='lg' show={showModal} onHide={handleCloseModal} centered >
            <Modal.Body>
                <CloseButton onClick={handleCloseModal}/>
                <div className='modal-img'>
                    <img src={modalImage} alt="Full screen image" className="img-fluid mx-auto d-block" />
                </div>
            </Modal.Body>
        </Modal>
        </Container>
    );
};