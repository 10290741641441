import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import workImg1 from "../assets/img/ima/ima4.PNG";
import workImg2 from "../assets/img/letvagy/letvagy3.PNG";
import workImg3 from "../assets/img/tollrajz/tollrajz20.PNG";
import workImg4 from "../assets/img/vegyes/vegyes18.PNG";

export const Works = () => {

    const workTypes = [
        {
            title: "Ima sorozat",
            imgUrl: workImg1,
            url: "/ima"
        },
        {
            title: "Létvágy sorozat",
            imgUrl: workImg2,
            url: "/letvagy"
        },
        {
            title: "Tollrajzok",
            imgUrl: workImg3,
            url: "/tollrajzok"
        },
        {
            title: "Vegyes technikák",
            imgUrl: workImg4,
            url: "/vegyes"
        }
    ]

    return (
        <section className="works" id="munkaim">
            <Container>
                <h1>Munkáim</h1>
                <Row className="work-row">            
                    {
                    workTypes.map((work, key) => {
                        return (
                            <Col xs={12} md={12} lg={6}> 
                                <div className="work-bx">
                                    <h2 className="work-title">{work.title}</h2>
                                    <img
                                    className="d-block w-100"
                                    src= {work.imgUrl}
                                    alt={work.title+key}
                                    />
                                    <Link to={work.url}>
                                        <Button className="work-btn" variant="dark">További képek</Button>
                                    </Link>
                                </div> 
                            </Col>
                        )}
                    )}
                </Row>
            </Container>
        </section>
    );
}