import { Container } from "react-bootstrap";
import { NavbarSecond } from "../components/NavbarSecond";
import { Gallery } from "../components/Gallery";

declare const require: {
  context: (
    directory: string,
    useSubdirectories?: boolean,
    regExp?: RegExp
  ) => {
    keys: () => string[];
    <T>(id: string): T;
  };
};

export const Vegyes = () => {
  const importAll = (r: any) => r.keys().map(r);
  const images = importAll(require.context(`../assets/img/vegyes`, false, /\.(png|PNG|jpe?g|svg)$/));

    return (
      <div>
        <NavbarSecond />
        <section className="subpage">
            <Container>
                <h1>Vegyes technika</h1>
                <Gallery images={images}/>
            </Container>
        </section>
      </div>
    );
}