import { useState, useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";

export const NavbarSecond = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => window.scrollY > 50 ? setScrolled(true) : setScrolled(false);

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <Navbar expand="lg" className={ scrolled ? "scrolled" : "" } variant="dark">
          <Container>
            <Navbar.Brand href="/">LÉVAI ÁDÁM</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
              <Nav.Link href="/" className={ activeLink === 'rolam' ? 'active navbar-link' : 'navbar-link' }>Vissza a főoldalra</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );
}