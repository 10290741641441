import { Container, Row, Col, Carousel } from "react-bootstrap";
import headerImg1 from "../assets/img/header/header-img1.PNG";
import headerImg2 from "../assets/img/header/header-img2.PNG";
import headerImg3 from "../assets/img/header/header-img3.PNG";
import headerImg4 from "../assets/img/header/header-img4.PNG";

export const Banner = () => {
    return(
        <section className="banner" id="">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <h1>„Rajzolás tölti be lapjaimat.”</h1>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <Carousel interval={3500} pause={false} fade controls={false} indicators={false}>
                            <Carousel.Item>
                                <div className="carousel-element" style={{ maxHeight: '700px' }}>
                                    <img
                                    className="d-block w-100 h-100"
                                    src= {headerImg1}
                                    alt="First slide"
                                    />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div style={{ maxHeight: '700px' }}>
                                    <img
                                    className="d-block w-100 h-100"
                                    src={headerImg2}
                                    alt="Second slide"
                                    />
                                 </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div style={{ maxHeight: '700px' }}>
                                    <img
                                    className="d-block w-100 h-100"
                                    src={ headerImg3 }
                                    alt="Third slide"
                                    />
                                 </div>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}